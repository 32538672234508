import * as React from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';

const CustomerRoot = styled.div`
	margin-top: 3rem;
	margin-bottom: 4rem;
`;

const CustomersLayout = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex: 1;
	flex-wrap: wrap;
	justify-content: space-between;
	& > * {
		margin-bottom: 1rem;
	}
	@media screen and (max-width: 600px) {
		justify-content: center;
		& > * {
			margin-bottom: 0;
		}
		& > :not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
`;

const HeaderLayout = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 2rem;
	@media screen and (max-width: 600px) {
		text-align: center;
	}
`;

interface CustomersContainerProps {
	title: string;
}

export const Customers: React.FC<CustomersContainerProps> = props => {
	const { title, children } = props;
	return (
		<CustomerRoot>
			<HeaderLayout>
				<HeaderH3 style={{ paddingBottom: '0.5rem' }}>{title}</HeaderH3>
				<img src={seperatorIndicator} alt="separator" />
			</HeaderLayout>
			<CustomersLayout>{children}</CustomersLayout>
		</CustomerRoot>
	);
};
