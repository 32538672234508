import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH3 } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';
import SolutionImg from '../svgs/solution-layout.svg';

const SolutionRoot = styled.div`
	margin-top: 5.5rem;
	margin-bottom: 6rem;
	display: flex;
	flex-direction: row;
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

const SolutionText = styled.div`
	margin-right: 1rem;
`;

interface ProductContainerProps {
	title: string;
	description: string;
}

export const Solution: React.FC<ProductContainerProps> = props => {
	const { title, description, children } = props;

	return (
		<SolutionRoot>
			<SolutionText>
				<HeaderH3 style={{ hyphens: 'auto' }}>{title}</HeaderH3>
				<img src={seperatorIndicator} alt="separator"></img>
				<Body style={{ marginTop: '2rem' }}>{description}</Body>
				{children}
			</SolutionText>
			<img src={SolutionImg} width="500" alt="solution image" />
		</SolutionRoot>
	);
};
