/**
 * Mesh lighting page
 *
 * @author Anja Bisako
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { AppDetails } from '../components/AppDetails';
import { Appstore } from '../components/Appstore';
import { Carousel } from '../components/Carousel';
import { Contact } from '../components/Contact';
import { Container } from '../components/Container';
import { Customers } from '../components/Customers';
import { Fact } from '../components/Fact';
import { Facts } from '../components/Facts';
import { MeshlightingFooter } from '../components/MeshlightingFooter';
import { Page } from '../components/Page';
import { Product } from '../components/Product';
import { ProductDetailContainer } from '../components/ProductDetailContainer';
import { ProductDetailsDescription } from '../components/ProductDetailsDescription';
import { ProductsContainer } from '../components/ProductsContainer';
import { Solution } from '../components/Solution';
import { Body, HeaderH2, HeaderH5, Subtitle, Title } from '../components/Typography';
import { IndexLayout } from '../layouts';
import lightingColors from '../styles/lighting';

import BulletPoint from '../svgs/bullet-point.svg';
import Daimler from '../svgs/daimler.svg';
import Haefele from '../svgs/haefele.svg';
import Interstuhl from '../svgs/interstuhl.svg';
import Nimbus from '../svgs/nimbus.svg';
import AppIcon from '../svgs/product-app.svg';
import FirmwareIcon from '../svgs/product-firmware.svg';
import HubIcon from '../svgs/product-hub.svg';

const StyledLi = styled.div({
	display: 'flex',
	flexDirection: 'row',
	marginTop: '1rem',
});

const StyledUl = styled.div`
	margin-top: 1rem;
`;

const StyledListText = styled.div({
	marginLeft: '1rem',
});

const StyledA = styled.a({
	'&.anchor': {
		display: 'block',
		position: 'relative',
		top: '-8em',
		visibility: 'hidden',
	},
});

const StyledProductImage = styled.div`
	height: 450px;
	width: 100%;
	flex: 1;
	@media screen and (max-width: 600px) {
		height: 250px;
	}
`;

const BluetoothPage: React.FC = () => {
	const { t } = useTranslation('bluetooth');

	return (
		<IndexLayout>
			<Page>
				<Container>
					<div style={{ display: 'flex' }}>
						<Title>
							<HeaderH2>
								Bluetooth® Mesh - <br />
								{t('slogan')}
								<HeaderH2 style={{ display: 'inline', color: lightingColors.highlight.orange }}>
									.
								</HeaderH2>
							</HeaderH2>
							<Subtitle>{t('slogan.subtitle')}</Subtitle>
						</Title>
					</div>
					<Appstore />
				</Container>

				<Container>
					<StyledA className="anchor" id="Products" href="#Products" />
					<ProductsContainer title={t('products.overview.title')}>
						<Product
							image={AppIcon}
							title={t('product.overview.app')}
							details={t('product.overview.app.description')}
							url={'#app-details'}
						/>
						<Product
							image={FirmwareIcon}
							title={t('product.overview.firmware')}
							details={t('product.overview.firmware.description')}
							url={'#firmware-details'}
						/>
						<Product
							image={HubIcon}
							title={t('product.overview.hub')}
							details={t('product.overview.hub.description')}
							url={'#hub-details'}
						/>
					</ProductsContainer>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<Customers title={t('customers.title')}>
						<img src={Daimler} alt="daimler logo" />
						<img src={Haefele} alt="haefele logo" />
						<img src={Interstuhl} alt="interstuhl logo" />
						<img src={Nimbus} alt="nimbus logo" />
					</Customers>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<Solution title={t('solution.title')} description={t('solution.description')}>
						<HeaderH5>{t('solution.private')}</HeaderH5>
						<Body>{t('solution.private.description')}</Body>
						<HeaderH5>{t('solution.commercial')}</HeaderH5>
						<Body>{t('solution.commercial.description')}</Body>
						<HeaderH5>{t('solution.sensorsystems')}</HeaderH5>
						<Body>{t('solution.sensorsystems.description')}</Body>
					</Solution>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<Facts title={t('facts.title')}>
						<Fact title="50.000+" details={t('fact.produced')} />
						<Fact title="18" details={t('fact.devicetypes')} />
						<Fact title="1" details={t('fact.random')} />
					</Facts>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground" id="details">
				<Container>
					<ProductDetailContainer>
						<StyledA className="anchor" id="app-details" href="#app-details" />
						<Carousel>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/meshapp-screenshot.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Meshthings App"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.app.1')}
									text={t('product.details.app.description.1')}
								/>
							</div>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/meshapp-screenshot.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Meshthings App"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.app.2')}
									text={t('product.details.app.description.2')}
								/>
							</div>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/meshapp-screenshot.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Meshthings App"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.app.3')}
									text={t('product.details.app.description.3')}
								/>
							</div>
						</Carousel>
						<StyledA className="anchor" id="firmware-details" href="#firmware-details" />
						<Carousel>
							<div>
								<ProductDetailsDescription
									title={t('product.details.firmware.1')}
									text={t('product.details.firmware.description.1')}
								/>
								<StyledProductImage>
									<StaticImage
										src="../content/example-programming.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Meshlighting Firmware"
									/>
								</StyledProductImage>
							</div>
							<div>
								<ProductDetailsDescription
									title={t('product.details.firmware.2')}
									text={t('product.details.firmware.description.2')}
								/>
								<StyledProductImage>
									<StaticImage
										src="../content/sales-suitcase.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Development Hardware"
									/>
								</StyledProductImage>
							</div>
							<div>
								<ProductDetailsDescription
									title={t('product.details.firmware.3')}
									text={t('product.details.firmware.description.3')}
								/>
								<StyledProductImage>
									<StaticImage
										src="../content/meshapp-ota.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Over the air update"
									/>
								</StyledProductImage>
							</div>
							<div>
								<ProductDetailsDescription
									title={t('product.details.firmware.4')}
									text={t('product.details.firmware.description.4')}
								/>
								<StyledProductImage>
									<StaticImage
										src="../content/example-flasher.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Smart Flasher"
									/>
								</StyledProductImage>
							</div>
						</Carousel>
						<StyledA className="anchor" id="hub-details" href="#hub-details" />
						<Carousel>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/hub-network.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Hub Network"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.hub.1')}
									text={t('product.details.hub.description.1')}
								/>
							</div>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/hub-network.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Hub Network"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.hub.2')}
									text={t('product.details.hub.description.2')}
								/>
							</div>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/example-chip.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="iM8X8M"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.hub.3')}
									text={t('product.details.hub.description.3')}
								/>
							</div>
							<div>
								<StyledProductImage>
									<StaticImage
										src="../content/example-hub-white.jpg"
										imgStyle={{ borderRadius: '8px' }}
										alt="Meshlithing Hub"
									/>
								</StyledProductImage>
								<ProductDetailsDescription
									title={t('product.details.hub.4')}
									text={t('product.details.hub.description.4')}
								/>
							</div>
						</Carousel>
					</ProductDetailContainer>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<AppDetails
						title={t('product.app.meshthings')}
						description={t('product.app.meshthings.description')}
						details={
							<StyledUl>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail1')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail2')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail3')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail4')}</StyledListText>
								</StyledLi>
							</StyledUl>
						}
					>
						<div style={{ width: '100%', flex: 1 }}>
							<StaticImage src="../content/meshapp-update.png" alt="Meshthings app" />
						</div>
					</AppDetails>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground" id="Contact">
				<Container>
					<Contact
						title={t('contact.title')}
						text={t('contact.description')}
						buttonText={t('contact.button')}
						url={'mailto:info@thingos.io'}
					/>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<MeshlightingFooter />
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default BluetoothPage;
