import * as React from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './Typography';

import seperatorIndicator from '../svgs/seperator-indicator.svg';

const FactRoot = styled.div`
	margin-top: 4rem;
	margin-bottom: 4rem;
`;

const HeaderLayout = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 2rem;
	@media screen and (max-width: 600px) {
		align-items: center;
		text-align: center;
	}
`;

const FactsLayout = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	justify-content: space-around;
	text-align: center;
	flex-wrap: wrap;

	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
`;

interface FactsContainerProps {
	title?: string;
}

export const Facts: React.FC<FactsContainerProps> = props => {
	const { title, children } = props;

	return (
		<FactRoot>
			{title != null && (
				<HeaderLayout>
					<HeaderH3 style={{ paddingBottom: '0.5rem' }}>{title}</HeaderH3>
					<img src={seperatorIndicator} alt="separator" />
				</HeaderLayout>
			)}
			<FactsLayout>{children}</FactsLayout>
		</FactRoot>
	);
};
