import * as React from 'react';
import styled from 'styled-components';

const ProductLayout = styled.div`
	padding-bottom: 1rem;
	padding-right: 1rem;
	display: flex;
	flex: 1;
	flex-direction: column;
`;

interface ProductDetailsDescriptionProps {
	title: string;
	text: string;
}

export const ProductDetailsDescription: React.FC<ProductDetailsDescriptionProps> = () => (
	<ProductLayout>
		{/* <HeaderH3 style={{marginBottom: '2rem'}}>{title}</HeaderH3>
		<Body>{text}</Body> */}
	</ProductLayout>
);
