import * as React from 'react';
import styled from 'styled-components';

import lightingColors from '../styles/lighting';
import { Body, HeaderH3 } from './Typography';

const FactLayout = styled.div`
	width: 33%;
	padding-bottom: 1rem;

	@media screen and (max-width: 600px) {
		width: 100%;
	}
`;

interface FactProps {
	title: string;
	details: string;
}

export const Fact: React.FC<FactProps> = props => {
	const { title, details } = props;
	return (
		<FactLayout>
			<div
				style={{
					background: 'linear-gradient(to right, #f99b43 0%, #f72580 100%)',
					WebkitBackgroundClip: 'text',
					WebkitTextFillColor: 'transparent',
				}}
			>
				<HeaderH3 style={{ color: lightingColors.highlight.orange }}>{title}</HeaderH3>
			</div>
			<Body>{details}</Body>
		</FactLayout>
	);
};
