/**
 * Custom Carousel Component
 *
 * @author Anja Bisako
 * @version 0.0.1
 */
import * as React from 'react';
import 'react-multi-carousel/lib/styles.css';
import styled from 'styled-components';

import Icon from '@thingos/design-icons/web';

import { Markdown } from './Markdown';
import { HeaderH3 } from './Typography';

import seperatorIndicatorDisabled from '../svgs/seperator-indicator-disabled.svg';
import seperatorIndicator from '../svgs/seperator-indicator.svg';

const MobileAlignment = styled.div<{ direction: 'row' | 'row-reverse' }>(props => ({
	display: 'flex',
	flexDirection: props.direction,
	flex: 1,
	'@media screen and (max-width: 600px)': {
		flexDirection: 'column',
	},
}));

const FlexImage = styled.div<{ marginRight: string | number; marginLeft: string | number }>(
	props => ({
		display: 'flex',
		flexDirection: 'row',
		width: '80%',
		marginRight: props.marginRight,
		marginLeft: props.marginLeft,
		'@media screen and (max-width: 600px)': {
			flexDirection: 'column',
			marginBottom: '1rem',
			width: '100%',
			marginRight: 0,
			marginLeft: 0,
		},
	})
);

const TextArea = styled.div`
	display: flex;
	flex-direction: column;
	width: 60%;
	color: #fff;
	@media screen and (max-width: 600px) {
		margin-bottom: 1rem;
		width: 100%;
	}
`;

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledArrow = styled.div({
	width: 40,
	height: 40,
	borderRadius: 40,
	backgroundColor: 'rgba(0,0,0,0.3)',
	marginRight: '0.5rem',
});

export const Carousel: React.FC = props => {
	const { children } = props;
	const childrenArray = React.Children.toArray(children);
	const maxSteps = childrenArray.length;

	const [activeStep, setActiveStep] = React.useState(0);

	const handlePrevious = () => {
		setActiveStep(prevActiveStep => {
			if (prevActiveStep == 0) return maxSteps - 1;
			else return prevActiveStep - 1;
		});
	};

	const handleNext = () => {
		setActiveStep(prevActiveStep => {
			if (prevActiveStep == maxSteps - 1) return 0;
			else return prevActiveStep + 1;
		});
	};

	const arrayOfKids = React.Children.toArray(children);
	const kid: any = arrayOfKids[activeStep];

	let imageIndex = 0;
	let imageArea = kid.props.children[0];
	let textArea = kid.props.children[1];
	if (kid.props.children && kid.props.children[0].props.title) {
		imageIndex = 1;
		textArea = kid.props.children[0];
		imageArea = kid.props.children[1];
	}

	return (
		<FlexRow>
			<MobileAlignment direction={imageIndex === 1 ? 'row-reverse' : 'row'}>
				{/* image */}
				<FlexImage
					marginRight={imageIndex == 1 ? '0' : '2rem'}
					marginLeft={imageIndex == 1 ? '2rem' : 0}
				>
					{imageArea}
				</FlexImage>
				<TextArea>
					{/* arrows */}
					<FlexRow style={{ color: '#fff' }}>
						<StyledArrow onClick={handlePrevious}>
							<Icon kind="ui/arrowLeft" height="40" width="40" color="#fff" />
						</StyledArrow>
						<StyledArrow onClick={handleNext}>
							<Icon kind="ui/arrowRight" height="40" width="40" color="#fff" />
						</StyledArrow>
					</FlexRow>

					{/* title */}
					<HeaderH3 style={{ marginTop: '0.5rem' }}>{textArea.props.title}</HeaderH3>

					{/* dots */}
					<FlexRow>
						{childrenArray.map((_element, index) => {
							return (
								<img
									key={index}
									src={index === activeStep ? seperatorIndicator : seperatorIndicatorDisabled}
									onClick={() => setActiveStep(index)}
									style={{ marginRight: '0.5rem', paddingTop: '1rem', paddingBottom: '1rem' }}
									alt={`pic ${index + 1}`}
								/>
							);
						})}
					</FlexRow>

					{/* text */}
					<Markdown markdown={textArea.props.text} />
				</TextArea>
			</MobileAlignment>
		</FlexRow>
	);
};
